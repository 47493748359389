/*eslint-disable */
import {bus} from '@/main';  
import { mapGetters } from 'vuex'; 
import uploadPrescDataPopup from '@/components/private/upload-presc-data';
import updateProfileInfo from '@/components/private/update-profile-info-popup/index.vue';
export default {
  name: 'pres-info-list',
  components: {
    'upload-presc-data-popup':uploadPrescDataPopup,
    'update-profile-popup' : updateProfileInfo,
  },
  props: [],
  data () {
    return {
      uploadPrescDataPopupState:false,
      presListData:null,
      presListDataResults:[],
      selfUserDetailData:null,
      updatedProfilePopup:false,
    }
  },
  computed: {
    ...mapGetters([
      'getDoctorPrescriptionsGetters',
      'selfUserDetailGetters'
    ]),
    getDoctorPrescriptionsMethod() {
       this.presListData = this.$store.getters.getDoctorPrescriptionsGetters.data;
       return;
    },
    setInitialPresListMethod(){
      if(this.presListData){
        this.presListDataResults = this.presListData.data
      }
      return;
    },
    getSelfUserInfoMethod() {
      this.selfUserDetailData = this.$store.getters.selfUserDetailGetters.data;
      return;
    },
  },
  mounted () {
    this.getDoctorPrescriptionsMethod
    this.setInitialPresListMethod
    bus.$on('uploadPrescDataPopupStateBus', (data) => {
      this.uploadPrescDataPopupState = data
    });
    bus.$on('updatedProfilePopupBus', (data) => {
      this.updatedProfilePopup = data
    });
  },
  methods: {
    updatedProfilePopupMethod(){
      document.body.classList.add('popup_open_body')
      this.updatedProfilePopup = true
    },
    uploadPrescDataPopupMethod(){
      document.body.classList.add('popup_open_body')
      this.uploadPrescDataPopupState = true
    }
  },
  watch:{
    getDoctorPrescriptionsMethod() {
      return this.$store.getters.getDoctorPrescriptionsGetters;
    },
    setInitialPresListMethod(){
      if(this.presListData){
        this.presListDataResults = this.presListData.data
      }
      return;
    },
    getSelfUserInfoMethod() {
      return this.$store.getters.selfUserDetailGetters;
    },
  },
  beforeRouteLeave: function (to, from, next) {
    document.body.classList.remove('popup_open_body')
    next();
  },
}