import { render, staticRenderFns } from "./update-profile-info-popup.html?vue&type=template&id=4690df4b&scoped=true&"
import script from "./update-profile-info-popup.js?vue&type=script&lang=js&"
export * from "./update-profile-info-popup.js?vue&type=script&lang=js&"
import style0 from "./update-profile-info-popup.scss?vue&type=style&index=0&id=4690df4b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4690df4b",
  null
  
)

export default component.exports